import * as React from 'react'
import { Icon, IconProps, Typography, cn } from '@design-system'
import { cva, type VariantProps } from 'class-variance-authority'

export const PILL_COLORS = [
  'danger-2',
  'blue-5',
  'cyan-5',
  'danger-5',
  'danger-10',
  'green-5',
  'notice-5',
  'orange-5',
  'pink-5',
  'purple-5',
  'purple-10',
  'orange-10',
  'turquoise-5',
  'yellow-5',
  'yellow-10',
  'gray-10',
  'green-20',
  'yellow-20',
  'warning-20',
  'notice-25',
  'notice-30',
  'success-30',
  'blue-40',
  'green-40',
  'success-40',
  'danger-50',
  'danger-60',
  'blue-60',
] as const

const pillVariants = cva(
  'flex border whitespace-nowrap items-center font-bold gap-2 px-2 py-1 justify-around w-fit text-gray-100 *:flex',
  {
    variants: {
      disabled: {
        true: 'text-opacity-50',
        false: ''
      },
      rounded: {
        true: 'rounded-full',
        false: 'rounded-sm'
      },
      variant: {
        contained: '',
        outlined: 'dark:text-white',
        blur: 'dark border-transparent backdrop-blur-xl bg-alpha/10 text-white'
      },
      color: {
        ...PILL_COLORS.reduce((acc, color) => {
          acc[color] = ''
          return acc
        }, {} as Record<typeof PILL_COLORS[number], string>),
      },
    },
    defaultVariants: {
      rounded: false,
      disabled: false,
      variant: 'contained',
      color: 'gray-10',
    },
    compoundVariants: [
      // contained
      { color: 'gray-10', variant: 'contained', className: 'bg-gray-10 dark:bg-gray-90 border-gray-10 dark:border-gray-90 dark:text-white' },
      { color: 'turquoise-5', variant: 'contained', className: 'bg-turquoise-5 border-turquoise-5' },
      { color: 'green-5', variant: 'contained', className: 'bg-green-5 border-green-5' },
      { color: 'cyan-5', variant: 'contained', className: 'bg-cyan-5 border-cyan-5' },
      { color: 'pink-5', variant: 'contained', className: 'bg-pink-5 border-pink-5' },
      { color: 'purple-5', variant: 'contained', className: 'bg-purple-5 border-purple-5' },
      { color: 'purple-10', variant: 'contained', className: 'bg-purple-10 border-purple-10' },
      { color: 'orange-10', variant: 'contained', className: 'bg-orange-10 border-orange-10' },
      { color: 'notice-5', variant: 'contained', className: 'bg-notice-5 border-notice-5' },
      { color: 'blue-5', variant: 'contained', className: 'bg-blue-5 border-blue-5' },
      { color: 'orange-5', variant: 'contained', className: 'bg-orange-5 border-orange-5' },
      { color: 'yellow-5', variant: 'contained', className: 'bg-yellow-5 border-yellow-5' },
      { color: 'yellow-10', variant: 'contained', className: 'bg-yellow-10 border-yellow-10' },
      { color: 'success-30', variant: 'contained', className: 'bg-success-30 border-success-30 text-green-80' },
      { color: 'success-40', variant: 'contained', className: 'bg-success-40 border-success-40' },
      { color: 'blue-40', variant: 'contained', className: 'bg-blue-40 border-blue-40 text-white' },
      { color: 'blue-60', variant: 'contained', className: 'bg-blue-60 border-blue-60 text-white' },
      { color: 'danger-5', variant: 'contained', className: 'bg-danger-5 border-danger-5 text-danger-60' },
      { color: 'danger-10', variant: 'contained', className: 'bg-danger-10 border-danger-5' },
      { color: 'green-20', variant: 'contained', className: 'bg-green-20 border-green-20' },
      { color: 'yellow-20', variant: 'contained', className: 'bg-yellow-20 border-yellow-20' },
      { color: 'warning-20', variant: 'contained', className: 'bg-warning-20 border-warning-20' },
      { color: 'notice-25', variant: 'contained', className: 'bg-notice-25 border-notice-25' },
      { color: 'notice-30', variant: 'contained', className: 'bg-notice-30 border-notice-30' },
      { color: 'danger-50', variant: 'contained', className: 'bg-danger-50 border-danger-50 text-white' },
      { color: 'danger-60', variant: 'contained', className: 'bg-danger-60 border-danger-60 text-white' },
      { color: 'green-40', variant: 'contained', className: 'bg-green-40 border-green-40 text-white' },
      { color: 'danger-2', variant: 'contained', className: 'bg-danger-2 border-danger-2 text-danger-50' },
      // outlined
      { color: 'gray-10', variant: 'outlined', className: 'border-alpha/20 dark:text-white' },
      { color: 'danger-50', variant: 'outlined', className: 'border-danger-20 text-danger-50 dark:text-danger-20' },
    ],
  }
)

interface RootProps extends Omit<React.HTMLAttributes<HTMLDivElement>, 'color'>, VariantProps<typeof pillVariants> { }

function Root({ children, rounded, variant, color, disabled, className, ...props }: RootProps) {
  return (
    <div className={cn(pillVariants({ variant, rounded, color, disabled, className }))} {...props}>
      {children}
    </div>
  )
}

interface ValueProps extends React.PropsWithChildren {
  className?: string
}

function Value({ children, className }: ValueProps) {
  return (
    <Typography variant="footnote" weight="bold" className={cn("self-center text-inherit dark:text-inherit", className)}>
      {children}
    </Typography>
  )
}

export interface PillIconProps {
  /**
   * The name of the icon to render.
   */
  name: IconProps['name']
}

function PillIcon({ name }: PillIconProps) {
  return (
    <Icon size="xs" className="text-inherit" name={name} />
  )
}

export const Pill = Object.assign(Root, {
  Icon: PillIcon,
  Value,
})
