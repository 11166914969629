import PropTypes from 'prop-types'
import {
  faBold,
  faHeading,
  faImages,
  faItalic,
  faLink,
  faList,
  faListOl,
  faPaperclip,
  faQuoteRight,
  faUnderline,
  faUnlink,
  faX,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { forwardRef, useImperativeHandle, useEffect, useRef, useState } from 'react'
import isUrl from 'is-url'
import { Editor, Transforms, Element as SlateElement, Node as SlateNode, Range, createEditor } from 'slate'
import { Slate, Editable, withReact, useSlate, useSelected, useFocused, useSlateStatic, ReactEditor } from 'slate-react'
import { withHistory } from 'slate-history'
import isImage from '@utils/is-image'
import uploadFile from '@components/forms/slate-textarea/uploadFile'
import ImageElement from '@components/forms/slate-textarea/elements/image'
import VideoElement from '@components/forms/slate-textarea/elements/video'
import htmlToSlate from '@utils/htmlToSlate'
import { toast } from 'sonner'
import { Typography } from '@design-system'

const useEditor = () => {
  const [editor] = useState(() => withHistory(withEmbeds(withFiles(withLinks(withHTML(withReact(createEditor())))))))
  return editor
}

// unstyled for use in formative feedback
// I did not want to change the original rendered slate in case it impacted other pages
// eslint-disable-next-line react/prop-types
export const FFRenderedSlate = ({ value, placeholder }) => {
  const editor = useEditor()
  if (isBlankValue(value) && placeholder) return <p className="text-sm text-gray-70">{placeholder}</p>

  const emptyValue = [
    {
      type: 'paragraph',
      children: [{ text: '' }],
    },
  ]

  editor.children = value || emptyValue

  return (
    <Slate editor={editor} initialValue={valueOrEmptySlate(value)} onChange={() => null}>
      <div className="text-sm text-gray-70">
        <Editable
          data-cy="readonly-slate"
          readOnly
          renderElement={(props) => <Element {...props} />}
          renderLeaf={(props) => <Leaf {...props} />}
        />
      </div>
    </Slate>
  )
}

export const RenderedSlate = ({ value, placeholder, className }) => {
  const editor = useEditor()

  const isBlank = isBlankValue(value)
  if (isBlank && placeholder) return <>{placeholder}</>

  const emptyValue = [
    {
      type: 'paragraph',
      children: [{ text: '' }],
    },
  ]

  editor.children = !isBlank ? value : emptyValue

  return (
    //word-break: break-word;
    <Slate editor={editor} initialValue={valueOrEmptySlate(value)} onChange={() => null}>
      <div className={`${className ? className : 'slate-textearea-description'}`}>
        <Editable
          data-cy="readonly-slate"
          readOnly
          renderElement={(props) => <Element {...props} />}
          renderLeaf={(props) => <Leaf {...props} />}
          decorate={urlDecorator}
        />
      </div>
    </Slate>
  )
}

export const useTextOnlySlate = ({ value, placeholder }) => {
  if (isBlankValue(value)) {
    return placeholder
  }

  const contentOnly = valueOrEmptySlate(value)
    .map((n) => (n?.type === 'file' ? n?.filename || '' : SlateNode.string(n)))
    .join('\n')

  return contentOnly
}

const textToHyperlink = (t) => {
  const regex = /\b(https?:\/\/|www\.)\S*\b/g
  const result = (t || '').replace(regex, (match) => {
    const isValidHttpUrl = (s) => isUrl(s.startsWith('www.') ? 'http://' + s : s)
    if (!isValidHttpUrl(match)) return match

    const beforeChar = t.charAt(t.indexOf(match) - 1)
    const afterChar = t.charAt(t.indexOf(match) + match.length)
    if (['(', '['].includes(beforeChar) || [')', ']'].includes(afterChar)) {
      return match
    }

    return `<a href="${match}" target="_blank" class="hover:underline" rel="noreferrer">${match}</a>`
  })

  return <div dangerouslySetInnerHTML={{ __html: result }} />
}

export const TextOnlyWithLinkSlate = ({ value, placeholder, parseText, ...rest }) => {
  const contentOnly = useTextOnlySlate({ value, placeholder })
  const content = parseText ? parseText(contentOnly) : contentOnly
  return <div {...rest}>{textToHyperlink(content)}</div>
}
TextOnlyWithLinkSlate.propTypes = {
  placeholder: PropTypes.any,
  value: PropTypes.any,
  parseText: PropTypes.func,
}

export const TextOnlySlate = ({ value, placeholder, parseText, ...rest }) => {
  const contentOnly = useTextOnlySlate({ value, placeholder })
  const content = parseText ? parseText(contentOnly) : contentOnly
  return <div {...rest}>{content}</div>
}
TextOnlySlate.propTypes = {
  placeholder: PropTypes.any,
  value: PropTypes.any,
  parseText: PropTypes.func,
}

RenderedSlate.propTypes = {
  value: PropTypes.array,
  placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  className: PropTypes.string,
  blockSubmission: PropTypes.func,
}

export const isBlankValue = (value) => {
  if (!value) return true
  if (!SlateNode.isNodeList(value)) return true
  if (
    typeof value === 'object' &&
    (Object.keys(value).length === 0 || Object.values(value).filter(Boolean).length === 0)
  )
    return true
  if (Array.isArray(value) && value.filter(Boolean).length === 0) return true

  const hasNonTextElements = value.map(SlateNode.extractProps).some((nodeProps) => nodeProps.type !== 'paragraph')
  const hasOnlyEmptyTextElements = value.map(SlateNode.string).join('') === ''

  return hasOnlyEmptyTextElements && !hasNonTextElements
}

const valueOrEmptySlate = (value) => (!isBlankValue(value) ? value : [{ type: 'paragraph', children: [{ text: '' }] }])

const findUrlsInText = (text) => {
  const urlRegex =
    // eslint-disable-next-line no-useless-escape
    /(?:(?:https?|ftp|file):\/\/|www\.|ftp\.)(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[-A-Z0-9+&@#\/%=~_|$?!:,.])*(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[A-Z0-9+&@#\/%=~_|$])/gim

  const matches = text.match(urlRegex)

  return matches ? matches.map((m) => [m.trim(), text.indexOf(m.trim())]) : []
}

const urlDecorator = ([node, path]) => {
  const nodeText = node.text

  if (!nodeText) return []

  const urls = findUrlsInText(nodeText)
  return urls.map(([url, index]) => {
    return {
      anchor: {
        path,
        offset: index,
      },
      focus: {
        path,
        offset: index + url.length,
      },
      linkable: true,
    }
  })
}

const SlateTextarea = forwardRef(
  (
    {
      id,
      name,
      value,
      onChange,
      className = '',
      autoFocus = false,
      readOnly = false,
      blockSubmission,
      noAttachments = false,
      customTopBar,
      customBottomBar,
      placeholderText,
    },
    ref
  ) => {
    const editor = useEditor()

    const defaultValue = valueOrEmptySlate(value)
    const [localValue, setLocalValue] = useState(defaultValue)

    useImperativeHandle(ref, () => ({
      focus() {
        ReactEditor.focus(editor)
      },
      clear() {
        const emptyNode = [{ type: 'paragraph', children: [{ text: '' }] }]
        Transforms.deselect(editor)
        Transforms.removeNodes(editor, { at: Editor.range(editor, []) })
        editor.children = emptyNode
        setLocalValue(emptyNode)
      },
      appendValue(nodes) {
        Transforms.deselect(editor)
        const appended = [...editor.children, ...nodes]

        editor.children = appended
        onChange && onChange(appended)
        setLocalValue(appended)
      },

      isEmpty: () => {
        return isBlankValue(editor.children)
      },
    }))

    // Hack to ensure Slate does not crash when the user types something after highlighting a text.
    // Simulates the behavior of overwriting the text by deleting the selected content first then adding the typed value.
    // https://www.loom.com/share/3860337ec82349e7965d03f043c9c161?sid=8aae9da0-1bff-49ec-a871-70edf32c4a08
    const handleKeyDown = (event) => {
      if (event.ctrlKey || event.metaKey || event.altKey) return
      if (event.key.length === 1 || event.key === 'Enter') {
        const { selection } = editor
        if (selection && Range.isExpanded(selection)) {
          event.preventDefault()
          Transforms.delete(editor, { at: selection }) // Delete the selection
          if (event.key === 'Enter') {
            Transforms.insertNodes(
              // Handle Enter key
              editor,
              { type: 'paragraph', children: [{ text: '' }] },
              { split: true }
            )
          } else {
            Editor.insertText(editor, event.key)
          }
          return true
        }
      }
    }

    return (
      <>
        <input type="hidden" ref={ref} name={name} value={JSON.stringify(localValue)} />
        <Slate
          key={id}
          editor={editor}
          initialValue={defaultValue}
          onChange={(v) => {
            const isAstChange = editor.operations.some((op) => 'set_selection' !== op.type)
            if (isAstChange) {
              setLocalValue(v)
              onChange && onChange(v)
            }
          }}
        >
          <div
            className={`bg-white dark:bg-gray-90 flex flex-col border rounded-lg border-neutral-300 mt-0.5 w-full ${className} overflow-auto relative`}
          >
            {customTopBar}
            {!customTopBar && (
              <div className="px-1 flex items-center border-b sticky top-0 bg-white dark:bg-gray-90 z-5 h-[33px]">
                <BlockButton disabled={readOnly} format="heading-one" icon={<FontAwesomeIcon icon={faHeading} />} />
                <BlockButton disabled={readOnly} format="block-quote" icon={<FontAwesomeIcon icon={faQuoteRight} />} />
                <BlockButton disabled={readOnly} format="numbered-list" icon={<FontAwesomeIcon icon={faListOl} />} />
                <BlockButton disabled={readOnly} format="bulleted-list" icon={<FontAwesomeIcon icon={faList} />} />
                <LinkButton disabled={readOnly} />
                <MarkButton disabled={readOnly} format="bold" icon={<FontAwesomeIcon icon={faBold} />} />
                <MarkButton disabled={readOnly} format="italic" icon={<FontAwesomeIcon icon={faItalic} />} />
                <MarkButton disabled={readOnly} format="underline" icon={<FontAwesomeIcon icon={faUnderline} />} />
                {!noAttachments && (
                  <>
                    <InsertImageButton disabled={readOnly} format="image" icon={faImages} />
                    <InsertFileButton disabled={readOnly} format="file" icon={faPaperclip} />
                  </>
                )}
                {!readOnly && (
                  <>
                    <div className="h-[23px] w-px mx-3 bg-white dark:bg-gray-90" />
                    <ClearFieldButton onClick={setLocalValue} />
                  </>
                )}
              </div>
            )}
            <Editable
              placeholder={placeholderText}
              renderPlaceholder={({ children, attributes }) => (
                <div
                  {...{
                    style: {
                      userSelect: 'none',
                      position: 'absolute',
                      textDecoration: 'none',
                      top: 0,
                      pointerEvents: 'none',
                    },
                    'data-slate-placeholder': true,
                    contentEditable: false,
                    ref: attributes.ref,
                    dir: attributes.dir,
                  }}
                >
                  <Typography variant="body" className="pt-2 text-neutral-500">
                    {placeholderText}
                  </Typography>
                </div>
              )}
              className={`focus:outline-none scroll:auto py-2 px-3 w-full slate-textearea-description ${readOnly && 'bg-gray-10 cursor-not-allowed'
                }`}
              renderElement={(props) => <Element {...props} blockSubmission={blockSubmission} />}
              renderLeaf={(props) => <Leaf {...props} />}
              autoFocus={autoFocus}
              id={id}
              data-cy="slate-editable-text-area"
              readOnly={readOnly}
              onKeyDown={handleKeyDown}
            />
          </div>
          {customBottomBar}
        </Slate>
      </>
    )
  }
)

SlateTextarea.propTypes = {
  id: PropTypes.string.isRequired,
  autoFocus: PropTypes.bool,
  readOnly: PropTypes.bool,
  className: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.any,
  value: PropTypes.any,
  blockSubmission: PropTypes.func,
  noAttachments: PropTypes.func,
  customTopBar: PropTypes.element,
  customBottomBar: PropTypes.element,
  placeholderText: PropTypes.string,
}

SlateTextarea.displayName = 'SlateTextarea'

function getYoutubeId(url) {
  const youtubeRegex = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/
  const match = url.match(youtubeRegex)

  return match && match[2].length === 11 ? match[2] : null
}

function getVimeoId(url) {
  const vimeoRegex = /(?:vimeo)\.com.*(?:videos|video|channels|)\/([\d]+)/i
  const match = url.match(vimeoRegex)
  return match ? match[1] : null
}

const withEmbeds = (editor) => {
  const { insertData, isVoid } = editor
  editor.isVoid = (element) => (element.type === 'video' ? true : isVoid(element))

  editor.insertData = (data) => {
    const text = data.getData('text/plain')
    if (isUrl(text) && getYoutubeId(text)) {
      const url = `https://www.youtube.com/embed/${getYoutubeId(text)}`
      const embed = { type: 'video', url, children: [{ text: '' }] }
      Transforms.insertNodes(editor, embed)
      editor.onChange(editor.children)
    } else if (isUrl(text) && getVimeoId(text)) {
      const url = `https://player.vimeo.com/video/${getVimeoId(text)}`
      const embed = { type: 'video', url, children: [{ text: '' }] }
      Transforms.insertNodes(editor, embed)
      editor.onChange(editor.children)
    } else {
      insertData(data)
    }
  }
  return editor
}

const GenericFileElement = ({ attributes, children, element, blockSubmission = () => { } }) => {
  const editor = useSlateStatic()
  const selected = useSelected()
  const focused = useFocused()
  const [progress, setProgress] = useState()

  const actualFilename = element.filename || element.file?.name
  const ext = actualFilename?.split('.')[1]

  useEffect(() => {
    const file = element.file
    if (!element.url && file) {
      blockSubmission(true)
      const compressedFile = new File([file], file.name)
      const reader = new FileReader()
      reader.addEventListener('load', () => {
        const path = ReactEditor.findPath(editor, element)
        uploadFile(compressedFile, setProgress)
          .then((uploadedFile) => {
            const newProperties = {
              url: uploadedFile.url,
              filename: uploadedFile.name,
            }
            Transforms.setNodes(editor, newProperties, { at: path })
            editor.onChange(editor.children)
            blockSubmission(false)
          })
          .catch((error) => {
            toast.error(error.message)
            const newProperties = {
              error: error.message,
              filename: actualFilename,
            }
            Transforms.setNodes(editor, newProperties, { at: path })
            editor.onChange(editor.children)
            blockSubmission(false)
          })
      })
      reader.readAsDataURL(compressedFile)
    }
    return () => { }
    // TODO: We should remove & fix this in the future
    // eslint-disable-next-line
  }, [])

  return (
    <div {...attributes} contentEditable={false} style={{ caretColor: 'transparent', userSelect: 'none' }}>
      {children}
      <div className="flex flex-col items-center justify-center">
        <a
          href={element.url}
          download={actualFilename}
          className={`relative w-32 h-48 rounded border-2 ${element.error ? 'border-danger-10' : ''} ${selected && focused ? 'ring-3 ring-blue-30' : ''
            }`}
        >
          {progress !== undefined && progress < 100 && (
            <div className="absolute rounded flex items-center justify-center h-full w-full top-0 left-0 opacity-70 bg-black">
              <h1 className="text-white">{progress}%</h1>
            </div>
          )}
          <div
            className={`no-underline uppercase h-full w-full flex items-center justify-center ${element.error ? 'bg-danger-2' : ''
              }`}
          >
            <h3>{ext}</h3>
          </div>
        </a>
        <a href={element.url} download className="text-xs text-center m-auto max-w-60 p-2">
          {actualFilename}
        </a>
      </div>
    </div>
  )
}

GenericFileElement.propTypes = {
  attributes: PropTypes.any,
  children: PropTypes.any,
  element: PropTypes.shape({
    file: PropTypes.shape({
      name: PropTypes.any,
    }),
    error: PropTypes.string,
    filename: PropTypes.oneOfType([
      PropTypes.shape({
        split: PropTypes.func,
      }),
      PropTypes.string,
    ]),
    url: PropTypes.any,
  }),
  blockSubmission: PropTypes.func,
}

const Element = (props) => {
  const { attributes, children, element } = props
  switch (element.type) {
    case 'block-quote':
    case 'quote':
      return <blockquote {...attributes}>{children}</blockquote>
    case 'code':
      return (
        <pre>
          <code {...attributes}>{children}</code>
        </pre>
      )
    case 'heading-one':
      return <h1 {...attributes}>{children}</h1>
    case 'heading-two':
      return <h2 {...attributes}>{children}</h2>
    case 'list-item':
      return <li {...attributes}>{children}</li>
    case 'bulleted-list':
    case 'unordered-list':
      return <ul {...attributes}>{children}</ul>
    case 'numbered-list':
    case 'ordered-list':
      return <ol {...attributes}>{children}</ol>
    case 'image':
      return <ImageElement {...props} />
    case 'video':
      return <VideoElement {...props} />
    case 'file':
      return <GenericFileElement {...props} />
    case 'link':
      return (
        <a title={element.url} href={element.url} {...attributes} target="_blank" rel="noreferrer">
          {children}
        </a>
      )
    default:
      return <p {...attributes}>{children}</p>
  }
}

Element.propTypes = {
  attributes: PropTypes.any,
  children: PropTypes.any,
  element: PropTypes.shape({
    type: PropTypes.any,
    url: PropTypes.any,
  }),
  blockSubmission: PropTypes.func,
}

const Leaf = ({ attributes, children, leaf }) => {
  if (leaf.linkable) {
    children = (
      <a className="cursor-pointer" href={leaf.text} target="_blank" rel="noopener noreferrer">
        {children}
      </a>
    )
  }

  if (leaf.bold) {
    children = <strong {...attributes}>{children}</strong>
  }

  if (leaf.italic) {
    children = <em {...attributes}>{children}</em>
  }

  if (leaf.code) {
    children = <code {...attributes}>{children}</code>
  }

  if (leaf.underline) {
    children = <u {...attributes}>{children}</u>
  }

  if (leaf.strikethrough) {
    children = <del {...attributes}>{children}</del>
  }

  return <span {...attributes}>{children}</span>
}

Leaf.propTypes = {
  attributes: PropTypes.any,
  children: PropTypes.any,
  leaf: PropTypes.shape({
    bold: PropTypes.any,
    code: PropTypes.any,
    italic: PropTypes.any,
    strikethrough: PropTypes.any,
    underline: PropTypes.any,
    linkable: PropTypes.bool,
    text: PropTypes.string,
  }),
}

const isBlockActive = (editor, format) => {
  const [match] = Editor.nodes(editor, {
    match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === format,
  })

  return !!match
}

const isVoidActive = (editor, format) => {
  const [match] = Editor.nodes(editor, {
    match: (n) => {
      return editor.isVoid(n) && SlateElement.isElement(n) && n.type === format
    },
  })
  return !!match
}

const isMarkActive = (editor, format) => {
  const marks = Editor.marks(editor)
  return marks ? marks[format] === true : false
}

const toggleMark = (editor, format) => {
  const isActive = isMarkActive(editor, format)

  if (isActive) {
    Editor.removeMark(editor, format)
  } else {
    Editor.addMark(editor, format, true)
  }
}

const LIST_TYPES = ['numbered-list', 'bulleted-list']

const toggleBlock = (editor, format) => {
  const isActive = isBlockActive(editor, format)
  const isList = LIST_TYPES.includes(format)

  Transforms.unwrapNodes(editor, {
    match: (n) => LIST_TYPES.includes(!Editor.isEditor(n) && SlateElement.isElement(n) && n.type),
    split: true,
  })

  const newProperties = {
    type: isActive ? 'paragraph' : isList ? 'list-item' : format,
  }
  Transforms.setNodes(editor, newProperties)

  if (!isActive && isList) {
    const block = { type: format, children: [] }
    Transforms.wrapNodes(editor, block)
  }

  editor.onChange(editor.children)
}

const withLinks = (editor) => {
  const { insertData, insertText, isInline } = editor

  editor.isInline = (element) => {
    return element.type === 'link' ? true : isInline(element)
  }

  editor.insertText = (text) => {
    if (text && isUrl(text)) {
      wrapLink(editor, text)
    } else {
      insertText(text)
    }
  }

  editor.insertData = (data) => {
    const text = data.getData('text/plain')

    if (text && isUrl(text)) {
      wrapLink(editor, text)
    } else {
      insertData(data)
    }
  }

  return editor
}

const withHTML = (editor) => {
  const { insertData, isInline, isVoid } = editor

  editor.isInline = (element) => {
    return element.type === 'link' ? true : isInline(element)
  }

  editor.isVoid = (element) => {
    return element.type === 'image' ? true : isVoid(element)
  }

  editor.insertData = (data) => {
    const html = data.getData('text/html')

    if (html) {
      const fragment = htmlToSlate(html)
      Transforms.insertFragment(editor, fragment)
      editor.onChange(editor.children)
    } else {
      insertData(data)
    }
  }

  return editor
}

const insertLink = (editor, url) => {
  if (editor.selection) {
    wrapLink(editor, url)
  }
}

const isLinkActive = (editor) => {
  return isBlockActive(editor, 'link')
}

const unwrapLink = (editor) => {
  Transforms.unwrapNodes(editor, {
    match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'link',
  })
  editor.onChange(editor.children)
}

const wrapLink = (editor, url) => {
  if (isLinkActive(editor)) {
    unwrapLink(editor)
  }

  const { selection } = editor
  const isCollapsed = selection && Range.isCollapsed(selection)
  const link = {
    type: 'link',
    url,
    children: isCollapsed ? [{ text: url }] : [],
  }

  if (isCollapsed) {
    Transforms.insertNodes(editor, link)
  } else {
    Transforms.wrapNodes(editor, link, { split: true })
    Transforms.collapse(editor, { edge: 'end' })
  }
  editor.onChange(editor.children)
}

export const LinkButton = ({ ...props }) => {
  const editor = useSlate()
  if (isLinkActive(editor))
    return (
      <Button
        {...props}
        isActive={true}
        className="disabled:cursor-not-allowed"
        onMouseDown={(event) => {
          event.preventDefault()
          if (isLinkActive(editor)) {
            unwrapLink(editor)
          }
        }}
      >
        <FontAwesomeIcon icon={faUnlink} />
      </Button>
    )
  return (
    <Button
      {...props}
      isActive={false}
      className="disabled:cursor-not-allowed"
      onMouseDown={(event) => {
        event.preventDefault()
        const url = window.prompt('Enter the URL of the link:')
        if (!url) return
        insertLink(editor, url)
      }}
    >
      <FontAwesomeIcon icon={faLink} />
    </Button>
  )
}

export const BlockButton = ({ format, icon, ...props }) => {
  const editor = useSlate()
  return (
    <Button
      {...props}
      className="disabled:cursor-not-allowed"
      type="button"
      isActive={isBlockActive(editor, format)}
      onMouseDown={(event) => {
        event.preventDefault()
        toggleBlock(editor, format)
      }}
    >
      {icon}
    </Button>
  )
}

BlockButton.propTypes = {
  format: PropTypes.any,
  icon: PropTypes.any,
}

const Button = ({ isActive, className = '', children, ...props }) => {
  return (
    <button
      type="button"
      className={`w-8 h-8 ${props.disabled ? '' : 'hover:bg-gray-20'} ${isActive && !props.disabled ? 'bg-blue-60 hover:bg-blue-70 text-white opacity-100' : 'opacity-50'
        } ${className}`}
      {...props}
    >
      {children}
    </button>
  )
}

Button.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  isActive: PropTypes.any,
  disabled: PropTypes.bool,
}

export const MarkButton = ({ format, icon, ...props }) => {
  const editor = useSlate()
  return (
    <Button
      {...props}
      className="disabled:cursor-not-allowed"
      isActive={isMarkActive(editor, format)}
      onMouseDown={(event) => {
        event.preventDefault()
        toggleMark(editor, format)
      }}
    >
      {icon}
    </Button>
  )
}

MarkButton.propTypes = {
  format: PropTypes.any,
  icon: PropTypes.any,
}

const InsertFileButton = ({ icon }) => {
  const editor = useSlate()
  const fileInputRef = useRef(null)

  const handleFileChange = (e) => {
    for (const file of e.target.files) {
      insertGenericFile(editor, {
        file,
      })
    }
  }

  return (
    <Button
      isActive={isVoidActive(editor, 'file')}
      onMouseDown={(event) => {
        event.preventDefault()
        fileInputRef.current.click()
      }}
    >
      <input type="file" className="hidden" onChange={handleFileChange} ref={fileInputRef} />
      <FontAwesomeIcon icon={icon} />
    </Button>
  )
}

InsertFileButton.propTypes = {
  icon: PropTypes.any,
}

const InsertImageButton = ({ icon }) => {
  const editor = useSlate()
  const fileInputRef = useRef(null)

  const handleFileChange = (e) => {
    for (const file of e.target.files) {
      const [mime] = file.type.split('/')
      if (mime === 'image') {
        insertImage(editor, {
          file,
        })
      } else {
        return window.alert('The file is not a image! If you want to upload a file, click on the paperclip.')
      }
    }
  }

  return (
    <Button
      isActive={isVoidActive(editor, 'image')}
      onMouseDown={(event) => {
        event.preventDefault()
        fileInputRef.current.click()
      }}
    >
      <input type="file" className="hidden" onChange={handleFileChange} ref={fileInputRef} />
      <FontAwesomeIcon icon={icon} />
    </Button>
  )
}

InsertImageButton.propTypes = {
  icon: PropTypes.any,
}

const ClearFieldButton = ({ onClick }) => {
  const editor = useSlate()

  const handleClearField = () => {
    const emptyNode = [{ type: 'paragraph', children: [{ text: '' }] }]
    Transforms.deselect(editor)
    Transforms.removeNodes(editor, { at: Editor.range(editor, []) })
    editor.children = emptyNode
    onClick && onClick(emptyNode)
  }

  return (
    <Button
      title="Erase field content"
      onClick={(event) => {
        event.preventDefault()
        handleClearField()
      }}
    >
      <FontAwesomeIcon icon={faX} />
    </Button>
  )
}
ClearFieldButton.propTypes = {
  onClick: PropTypes.func,
}

const withFiles = (editor) => {
  const { insertData, isVoid } = editor

  editor.isVoid = (element) => {
    return element.type === 'image' || element.type === 'file' ? true : isVoid(element)
  }

  editor.insertData = (data) => {
    const text = data.getData('text/plain')
    const { files } = data

    if (files && files.length > 0) {
      for (const file of files) {
        const [mime] = file.type.split('/')
        if (mime === 'image') {
          insertImage(editor, { file })
        } else {
          insertGenericFile(editor, { file })
        }
      }
    } else if (isImageUrl(text)) {
      insertImage(editor, {
        url: text,
      })
    } else if (isFileUrl(text)) {
      insertGenericFile(editor)
    } else {
      insertData(data)
    }
  }

  return editor
}

const insertImage = (editor, { url, file }) => {
  const text = { text: '' }
  const imageNode = { type: 'image', url, file, children: [text] }
  Transforms.insertNodes(editor, imageNode)
  editor.onChange(editor.children)
}

const insertGenericFile = (editor, { file } = {}) => {
  const text = { text: '' }
  const fileNode = { type: 'file', file, children: [text], date: Date.now() }
  Transforms.insertNodes(editor, fileNode)
  editor.onChange(editor.children)
}

const getFileExtensionFromUrl = (url) => {
  try {
    new URL(url, window.location.protocol + '//' + window.location.hostname).pathname.split('.').pop()
  } catch (err) {
    return false
  }
}

const verifyURLFormat = (url) =>
  new RegExp(/^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/).test(url)

const isFileUrl = (url) => {
  if (!url) return false
  const ext = getFileExtensionFromUrl(url)
  if (!ext || ext === '/') return false
  const isURL = verifyURLFormat(url)

  if (isURL) {
    return true
  }
  return false
}

const isImageUrl = (url) => {
  if (!isFileUrl(url)) return false
  const ext = getFileExtensionFromUrl(url)
  if (!ext || ext === '/') return false

  const isURL = verifyURLFormat(url)

  if (isURL) {
    return isImage(ext)
  } else {
    return false
  }
}

export default SlateTextarea
